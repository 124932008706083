<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <BaseModal size="lg" :title="formTitle" :show="isModalOpen" @update:show="value => toggleModal({show:value})">
      <template #default>
        <CForm>
          <ValidationProvider name="Tên loại hoạt động" rules="required" v-slot="{ errors, valid , touched }">
            <div role="group" class="form-group form-row">
              <label for="loaiHoatDong" class="col-form-label col-sm-3">Loại hoạt động </label>
              <div class="col-sm-9">
                <input id="loaiHoatDong" type="text" class="form-control"
                       :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                       v-model="editedItem.loaiHoatDong"/>
                <div class="invalid-feedback" v-if="!valid">
                  <ul>
                    <li v-for="e in errors" :key="e">{{ e }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </ValidationProvider>
          <CTextarea label="Mô tả" horizontal :value.sync="editedItem.moTa"/>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
            <CCol sm="9" class="form-inline">
              <CInputCheckbox label="Hoạt động" :inline="true" :checked.sync="editedItem.trangThai"/>
            </CCol>
          </CRow>
        </CForm>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
        <button type="button" class="btn btn-primary" :disabled="invalid || isSaving" @click="handleSubmit(saveItem)">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lưu
          lại
        </button>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>

<script>
import * as types from '@/store/modules/loai-hoat-dong/actionTypes'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LoaiHoatDongCreateOrUpdateModal',
  data () {
    return {
      invalid: true
    }
  },
  computed: {
    ...mapGetters('loaiHoatDong', {
      formTitle: 'formTitle',
      isSaving: 'isSaving',
      isModalOpen: 'isCreateOrUpdateModalOpen',
      editedItem: 'editedItem',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('loaiHoatDong', {
      toggleModal: types.TOGGLE_CREATE_UPDATE_MODAL,
      createItem: types.CREATE_LOAIHOATDONG,
      updateItem: types.UPDATE_LOAIHOATDONG
    }),
    async saveItem () {
      const validate = await this.$refs.form.validate()
      if (validate) {
        if (this.editedItem.id) {
          await this.updateItem(this.editedItem)
        } else {
          await this.createItem(this.editedItem)
        }
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          this.toggleModal({ show: false })
          this.$emit('refresh-list', true)
        }
      }
    }
  }
}
</script>
