<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Loại hoạt động
        </CCardHeader>
        <CCardBody>
          <BaseTable :fields="fields"
                     :items="dataSourcePaging.items"
                     :items-per-page="dataSourcePaging.pageSize"
                     :total-pages="dataSourcePaging.totalPages"
                     :loading="isFetchingList"
                     @page-change="pageChange"
                     @update:sorter-value="changeSorterValue"
                     pagination
                     sorter>
            <template #over-table>
              <CRow>
                <CCol sm="6">
                  <CForm>
                    <CInput label="Loại hoạt động" horizontal :value.sync="filterModel.loaiHoatDong"/>
                    <CSelect label="Trạng thái" horizontal :options="optionsTrangThai"
                             :value.sync="filterModel.trangThai"/>
                  </CForm>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CButton class="mb-3 mr-3" color="primary" :disabled="isFetchingList" @click="search">
                    <CIcon name="cil-search"/>&nbsp;Tìm kiếm
                  </CButton>
                  <CButton class="mb-3" color="primary" @click="toggleCreateOrUpdateModal({show:true})"
                           v-if="permissions.isAdd">
                    <CIcon name="cil-plus"/>&nbsp;Thêm mới
                  </CButton>
                </CCol>
              </CRow>
            </template>
            <template #trangThai="{item}">
              <td>{{ displayLabel(optionsTrangThai, item.trangThai) }}</td>
            </template>
            <template #action="{item}">
              <td>
                <CButton size="sm" color="info" class="mr-2" @click="toggleDetailModal({show:true, item})"
                         title="Chi tiết">
                  <CIcon name="cil-options"/>
                </CButton>
                <CButton size="sm" color="primary" class="mr-2" @click="toggleCreateOrUpdateModal({show:true, item})"
                         title="Chỉnh sửa" v-if="permissions.isUpdate">
                  <CIcon name="cil-pencil"/>
                </CButton>
                <CButton size="sm" color="danger"
                         @click="toggleDeleteConfirmationModal({show:true, item})" title="Xóa"
                         v-if="permissions.isDelete">
                  <CIcon name="cil-x"/>
                </CButton>
              </td>
            </template>
          </BaseTable>
        </CCardBody>
      </CCard>
    </CCol>
    <LoaiHoatDongCreateOrUpdateModal @refresh-list="fetchListItem(true)"/>
    <LoaiHoatDongDetailModal/>
    <DeleteConfirmationModal
      title="Xóa loại hoạt động"
      :show="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="deletedItem"
      @update:show="value=> toggleDeleteConfirmationModal({show:value})" @confirm-destroy="confirmDestroy"/>
  </CRow>
</template>

<script>
import LoaiHoatDongCreateOrUpdateModal from '@/components/danh-muc/loai-hoat-dong/LoaiHoatDongCreateOrUpdateModal'
import LoaiHoatDongDetailModal from '@/components/danh-muc/loai-hoat-dong/LoaiHoatDongDetailModal'
import * as types from '@/store/modules/loai-hoat-dong/actionTypes'
import { mapActions, mapGetters } from 'vuex'
import { statuses, displayLabel } from '@/shared/appConstants'

export default {
  name: 'LoaiHoatDong',
  components: {
    LoaiHoatDongCreateOrUpdateModal,
    LoaiHoatDongDetailModal
  },
  data () {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
          sorter: true
        },
        {
          key: 'loaiHoatDong',
          label: 'Tên loại hoạt động',
          sorter: true
        },
        {
          key: 'moTa',
          label: 'Mô tả',
          sorter: false
        },
        {
          key: 'trangThai',
          label: 'Trạng thái',
          sorter: true
        },
        {
          key: 'action',
          label: 'Thao tác',
          sorter: false
        }
      ],
      optionsTrangThai: statuses,
      filterModel: {
        loaiHoatDong: '',
        trangThai: null,
        orderBy: null,
        pageNumber: 1,
        pageSize: 15
      }
    }
  },
  computed: {
    ...mapGetters('loaiHoatDong', {
      dataSourcePaging: 'dataSourcePaging',
      isFetchingList: 'isFetchingList',
      deletedItem: 'deletedItem',
      isDeleting: 'isDeleting',
      isDeleteConfirmationModalOpen: 'isDeleteConfirmationModalOpen',
      responseResult: 'responseResult'
    }),
    ...mapGetters('auth', {
      permissions: 'permissionsDmLoaiHoatDong'
    })
  },
  methods: {
    ...mapActions('loaiHoatDong', {
      getListPaging: types.GET_LIST_LOAIHOATDONG_PAGING,
      toggleCreateOrUpdateModal: types.TOGGLE_CREATE_UPDATE_MODAL,
      toggleDeleteConfirmationModal: types.TOGGLE_DELETE_CONFIRMATION_MODAL,
      deleteItem: types.DELETE_LOAIHOATDONG,
      toggleDetailModal: types.TOGGLE_DETAIL_MODAL
    }),
    displayLabel,
    async fetchListItem (accept = true) {
      if (accept) {
        await this.getListPaging(this.filterModel)
      }
    },
    pageChange (page) {
      this.filterModel.pageNumber = page
      this.fetchListItem()
    },
    changeSorterValue (value) {
      this.filterModel.orderBy = value
      this.fetchListItem()
    },
    search () {
      this.filterModel.pageNumber = 1
      this.filterModel.orderBy = null
      this.fetchListItem()
    },
    async confirmDestroy (accept) {
      if (accept) {
        await this.deleteItem(this.deletedItem)
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          this.toggleDeleteConfirmationModal({ show: false })
          await this.fetchListItem()
        }
      }
    }
  },
  async mounted () {
    await this.fetchListItem()
  }
}
</script>
