<template>
  <BaseModal size="lg" title="Chi tiết loại hoạt động" :show="isModalOpen"
             @update:show="value => toggleModal({show:value})">
    <template #default>
      <CForm v-if="item.id && item.id > 0">
        <CInput label="Tên loại hoạt động" horizontal :value="item.loaiHoatDong"/>
        <CTextarea label="Mô tả" horizontal :value="item.moTa"/>
        <CRow form class="form-group">
          <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
          <CCol sm="9" class="form-inline">
            <CInputCheckbox label="Hoạt động" :inline="true" :checked="item.trangThai"/>
          </CCol>
        </CRow>
      </CForm>
      <CRow v-else>
        <CCol col="12" class="text-center">
          <span class="spinner-border" role="status" aria-hidden="true"></span>
        </CCol>
      </CRow>
    </template>
    <template #footer>
      <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
    </template>
  </BaseModal>
</template>

<script>
import * as types from '@/store/modules/loai-hoat-dong/actionTypes'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LoaiHoatDongDetailModal',
  computed: {
    ...mapGetters('loaiHoatDong', {
      item: 'detailItem',
      isModalOpen: 'isDetailModalOpen'
    })
  },
  methods: {
    ...mapActions('loaiHoatDong', { toggleModal: types.TOGGLE_DETAIL_MODAL })
  }
}
</script>

<style scoped>

</style>
